import styled from 'styled-components'
import { lg, sm, device, sh } from 'config/variables'

export const WorkingTeamStyle = styled.section`
  padding: 0 0 80px;
  background: linear-gradient(to bottom,rgba(242,220,204,0),#f8f9ff);
  margin: 0 0 87px;
  ${sh(`
    padding: 0 0 72px;
  `)}
  ${lg(`
    padding: 0 0 40px;
  `)}
  ${sm(`
    padding: 0 0 10px;
    margin: 0 0 50px;
  `)}
  .working-team-listing {
    display: flex;
    padding: 40px 0;
    ${sh(`
      padding: 36px 0;
    `)}
    .single-teamperson {
      max-width: 330px;
      width: 100%;
      margin: 0 20px;
      background: #d0e0cf;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      padding-bottom: 254px;
      transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      height:100%;
      ${sh(`
        max-width: 300px;
        margin: 0 18px;
      `)}
      ${sm(`
        max-width: 270px;
        margin: 0 15px;
        padding-bottom: 208px;
      `)}
      .content-block {
        padding: 28px 30px;
        padding-bottom: 0;
        position: relative;
        z-index: 1;
        ${sh(`
          padding: 26px 27px;
        `)}
        ${sm(`
          padding: 28px 20px;
        `)}
        h4 {
          font-size: 26px;
          line-height: 33px;
          margin-bottom: 7px;
          ${sh(`
            font-size: 24px;
            line-height: 31px;
          `)}
          ${sm(`
            font-size: 24px;
          `)}
        }
        .designation {
          font-size: 16px;
          line-height: 22px;
          display: block;
          margin-bottom: 28px;
          color: #546f52;
          font-weight: 500;
          ${sh(`
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 26px;
          `)}
        }
        p {
          font-size: 17px;
          line-height: 26px;
          margin-bottom: 20px;
          ${sh(`
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 18px;
          `)}
        }
        .tag-row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 41px;
          .tag {
            margin-right: 8px;
            font-size: 12px;
            line-height: 16px;
            padding: 6px 10px 7px;
            font-weight: 500;
            background: #b8cbb6;
            color: #546f52;
            text-transform: uppercase;
            margin-bottom: 8px;
          }
        }
      }
      .img-block {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      &.cloudy-blue {
        background: #aebad8;
        .content-block {
          .designation {
            color: #424e6e;
          }
          .tag-row {
            .tag {
              background: #97a8d0;
              color: #424e6e;
            }
          }
        }
      }
      &.soft-pink {
        background: #f3aec7;
        .content-block {
          .designation {
            color: #5e434d;
          }
          .tag-row {
            .tag {
              background: #c58da1;
              color: #5e434d;
            }
          }
        }
      }
      &.beige-three {
        background: #ccc3b8;
        .content-block {
          .designation {
            color: #656067;
          }
          .tag-row {
            .tag {
              background: #b9aea0;
              color: #656067;
            }
          }
        }
      }
      &.light-blue-grey-two {
        background: #c5d3e4;
        .content-block {
          .designation {
            color: #516178;
          }
          .tag-row {
            .tag {
              background: #aabed6;
              color: #516178;
            }
          }
        }
      }
      &.light-peach-six {
        background: #e5d5bd;
        .content-block {
          .designation {
            color: #5c594c;
          }
          .tag-row {
            .tag {
              background: #c9b9a3;
              color: #5c594c;
            }
          }
        }
      }
      &.powder-blue-two{
        background: #c1d6ea;
        .content-block {
          .designation {
            color: #4d5a67;
          }
          .tag-row {
            .tag {
              background: #b1c6da;
              color: #4d5a67;
            }
          }
        }
      }
      &.light-grey-blue-two {
        background: #a8bfcc;
        .content-block {
          .designation {
            color: #475a65;
          }
          .tag-row {
            .tag {
              background: #9bb3c1;
              color: #475a65;
            }
          }
        }
      }
      &.light-blue-grey-three{
        background: #bdc8d7;
        .content-block {
          .designation {
            color: #5a6b7b;
          }
          .tag-row {
            .tag {
              background: #aab8c9;
              color: #5a6b7b;
            }
          }
        }
      }
      &.faded-pink {
        background: #e5a5bc;
        .content-block {
          .designation {
            color: #5e434d;
          }
          .tag-row {
            .tag {
              background: #c58da1;
              color: #5e434d;
            }
          }
        }
      }
      &.cloudy-blue-two {
        background: #b2bfd5;
        .content-block {
          .designation {
            color: #0e1422;
          }
          .tag-row {
            .tag {
              background: #a0aec6;
              color: #0e1422;
            }
          }
        }
      }
      &.cloudy-blue-three {
        background: #b3bed2;
        .content-block {
          .designation {
            color: #4d5666;
          }
          .tag-row {
            .tag {
              background: #a1afc8;
              color: #4d5666;
            }
          }
        }
      }
      &.pale-salmon {
        background: #d7c6c4;
        .content-block {
          .designation {
            color: #5a4745;
          }
          .tag-row {
            .tag {
              background: #d3b7b4;
              color: #5a4745;
            }
          }
        }
      }
      &.cloudy-blue-four {
        background: #a9c7d3;
        .content-block {
          .designation {
            color: #44606b;
          }
          .tag-row {
            .tag {
              background: #93b6c4;
              color: #44606b;
            }
          }
        }
      }
      &.light-grey-blue-four {
        background: #a2b9d2;
        .content-block {
          .designation {
            color: #4b6077;
          }
          .tag-row {
            .tag {
              background: #90adcd;
              color: #4b6077;
            }
          }
        }
      }
      &.light-grey-blue {
        background: #9bb7d2;
        .content-block {
          .designation {
            color: #45576a;
          }
          .tag-row {
            .tag {
              background: #81a0be;
              color: #45576a;
            }
          }
        }
      }
      &.light-peach-seven{
        background: #e0d7ca;
        .content-block {
          .designation {
            color: #726b61;
          }
          .tag-row {
            .tag {
              background: #d7c7b0;
              color: #726b61;
            }
          }
        }
      }
      &.light-blue-grey-four {
        background: #bbcad4;
        .content-block {
          .designation {
            color: #667179;
          }
          .tag-row {
            .tag {
              background: #a9bbc8;
              color: #667179;
            }
          }
        }
      }
      &.cloudy-blue-five {
        background: #aad8d8;
        .content-block {
          .designation {
            color: #4c6e6e;
          }
          .tag-row {
            .tag {
              background: #82baba;
              color: #4c6e6e;
            }
          }
        }
      }
      &.cloudy-blue-six {
        background: #a7c9db;
        .content-block {
          .designation {
            color: #405763;
          }
          .tag-row {
            .tag {
              background: #729aae;
              color: #405763;
            }
          }
        }
      }
      &.pinkish-grey {
        background: #c4bfbf;
        .content-block {
          .designation {
            color: #675b5b;
          }
          .tag-row {
            .tag {
              background: #bbb1b1;
              color: #675b5b;
            }
          }
        }
      }
      &.cloudy-blue-seven {
        background: #b8becc;
        .content-block {
          .designation {
            color: #5a6172;
          }
          .tag-row {
            .tag {
              background: #a7aec0;
              color: #5a6172;
            }
          }
        }
      }
      &.light-blue-grey {
        background: #bcc7d6;
        .content-block {
          .designation {
            color: #5a6b7b;
          }
          .tag-row {
            .tag {
              background: #aab8c9;
              color: #5a6b7b;
            }
          }
        }
      }
      &.light-grey-blue-three {
        background: #a3c4d4;
        .content-block {
          .designation {
            color: #42687b;
          }
          .tag-row {
            .tag {
              background: #89afc2;
              color: #42687b;
            }
          }
        }
      }
      &.pinkish-grey-two {
        background: #cdbab6;
        .content-block {
          .designation {
            color: #685956;
          }
          .tag-row {
            .tag {
              background: #bdaaa6;
              color: #685956;
            }
          }
        }
      }
      &:hover {
        box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06),
          3px 0 5px -1px rgba(0, 0, 0, 0.1),
          6px 6px 15px 2px rgba(0, 0, 0, 0.1);
        transform: translate(0, -5px);
        ${device(`
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);;
          transform: initial;
        `)}
      }
    }
  }
`
