import React, { useEffect } from 'react'
import { WorkingTeamStyle } from './WorkingTeam.styles'
import { infiniteslide } from 'components/slider-main'
import Picture from 'components/Picture/Picture'

const WorkingTeam = ({ workingTeamdata }) => {
  const { workingTeamProps } = workingTeamdata
  let selector = 'working-team-listing'

  useEffect(() => {
    infiniteslide(`.${selector}`, { speed: 50 })
  }, [selector])

  return (
    <WorkingTeamStyle>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{workingTeamProps.heading}</h2>
        </div>
      </div>
      <div className={selector}>
        {workingTeamProps.workingTeamlistProps.map((teamProps, i) => {
          return (
            <div key={i} className="single-teamperson-wrap">
              <div
                className={`single-teamperson ${teamProps.workingPersonClass}`}
              >
                <div className="content-block">
                  <h4>{teamProps.workingPersonName}</h4>
                  <div className="designation">
                    {teamProps.workingPersonDesignation}
                  </div>
                  <p>{teamProps.workingPersonPara}</p>
                  <div className="tag-row">
                    {teamProps.workingPersonTag.map((tagProps, i) => {
                      return (
                        <div key={`${i}${i}`} className="tag">
                          {tagProps}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="img-block">
                  <Picture
                    height={193}
                    offset={2000}
                    srcSet={teamProps.workingPersonImg2x}
                    src={teamProps.workingPersonImg}
                    alt="teamProps.workingPersonAlt"
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </WorkingTeamStyle>
  )
}

export default WorkingTeam
